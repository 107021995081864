<template>
  <v-container>
    <template v-if="vueClientes">
      <v-row>
        <v-col cols="12">
          <PageToolbar
            :title="$tc('pages.cliente', 2)"
            icon="assignment_ind"
            dark
          />
        </v-col>

        <v-col cols="12">
          <ListaClientes />
        </v-col>
      </v-row>
      <v-btn
        v-if="vueAddCliente"
        :to="{ name: 'NovoCliente' }"
        fixed
        right
        bottom
        fab
        dark
        color="buttons"
        :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''"
      >
        <v-icon>add</v-icon>
      </v-btn>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Clientes",
  components: {
    ListaClientes: () => import("./components/ListaClientes.vue"),
  },
  data: () => ({}),
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueClientes() {
      return this.getAccess("vueClientes", "menu");
    },
    vueAddCliente() {
      return this.getAccess("vueCliente", "adicionar");
    },
  },
  methods: {},
  mounted() {},
  created() {},
};
</script>

<style></style>
